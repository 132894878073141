.head-text {
  position: relative;
  color: white;
}
.center__text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.shopImg {
  background-size: 100% 100%;
  width: 100%;
  object-fit: cover;
}
