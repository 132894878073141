.sliderImg {
  max-height: 500px; /* change this to whatever you want */
  width: auto;
  object-fit: cover;
}
.head-text {
  position: relative;
  color: white;
}
.ctext {
  position: absolute;
  top: 30%;
  left: 10%;
  right: 50%;
}
