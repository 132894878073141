@import url(//fonts.googleapis.com/css?family=Montserrat:300,400,500);
.post {
  width: 385px;
  margin: 0px 25px 40px 25px;
}

.postImg {
  width: 100%;
  height: 280px;
  margin-bottom: 10px;
  object-fit: cover;
  border-radius: 7px;
}

.postInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.postCat {
  font-family: "Varela Round", sans-serif;
  font-size: 11px;
  color: #be9656;
  line-height: 20px;
  margin-top: 15px;
  margin-right: 10px;
  cursor: pointer;
}

.postTitle {
  font-family: "Josefin Sans", sans-serif;
  font-size: 24px;
  font-weight: 700;
  margin-top: 15px;
  cursor: pointer;
}

.postDate {
  font-family: "Lora", serif;
  font-style: italic;
  font-size: 13px;
  color: #999;
  margin-top: 15px;
}

.postDesc {
  font-family: "Varela Round", sans-serif;
  font-size: 14px;
  color: #444;
  line-height: 24px;
  margin-top: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.posts{
    flex: 9 1;
    display: flex;
    flex-wrap: wrap;
    margin: 20px;
}
.sidebar {
  flex: 3 1;
  margin: 20px;
  padding-bottom: 30px;
  background-color: #fdfbfb;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebarItem {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebarTitle {
  margin: 10px;
  padding: 5px;
  width: 80%;
  border-top: 1px solid #a7a4a4;
  border-bottom: 1px solid #a7a4a4;
  font-family: "Varela Round", sans-serif;
  font-size: 12px;
  color: #222;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
}

.sidebarItem > img {
  margin-top: 15px;
}

.sidebarItem > p {
  padding: 30px;
}

.sidebarList {
  list-style: none;
  margin-bottom: 30px;
}

.sidebarListItem {
  display: inline-block;
  width: 50%;
  margin-top: 15px;
  cursor: pointer;
}

.sidebarSocial {
  margin-top: 15px;
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebarIcon{
    font-size: 30px;
    margin-left: 10px;
    cursor: pointer;
}
.a {
  color: black;
}
.sidebarSlogan{
  margin: 20px;
  margin-left: 30px;
  margin-right: 30px;
}
.sliderImg {
  max-height: 500px; /* change this to whatever you want */
  width: auto;
  object-fit: cover;
}
.head-text {
  position: relative;
  color: white;
}
.ctext {
  position: absolute;
  top: 30%;
  left: 10%;
  right: 50%;
}

.home{
    display: flex;
}
  #container {
    display: inline-block;
  }
 
.navbar-dark .navbar-brand {
  color: white;
  font-size: 30px;
  font-weight: 700;
  margin-left: 5%;
  font-family: "Roboto", sans-serif;
}
.topListItem {
  margin-right: 30px;
  font-size: 18px;
  font-weight: 300;
  cursor: pointer;
  color: white;
}
.topList {
  flex: 6 1;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  list-style: none;
  margin-right: 15px;
  margin-left: 15px;
  font-size: 18px;
  font-weight: 300;
  cursor: pointer;
}
.nav-link {
  font-family: "Roboto", sans-serif;
  color: white !important;
}

.singlePost {
  flex: 9 1;
}

.singlePostWrapper {
  /* background-color: #cccccc; */
  padding: 30px;
  display: flex;
  flex-direction: column;
}

img{
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  max-height: 100vh;
  margin: auto;
}

.singlePostImg {
  width: 100%;
  height: 400px;
  border-radius: 5px;
  object-fit: contain;
}

.singlePostTitle {
  text-align: center;
  margin: 10px;
  font-family: "Lora", serif;
  font-size: 28px;
}

.singlePostTitleInput {
  margin: 10px;
  font-family: "Lora", serif;
  font-size: 28px;
  text-align: center;
  border: none;
  color: gray;
  border-bottom: 1px solid lightgray;
}

.singlePostTitleInput:focus {
  outline: none;
}

.singlePostEdit {
  float: right;
  font-size: 16px;
}

.singlePostIcon {
  margin-left: 10px;
  cursor: pointer;
}

.singlePostIcon:first-child {
  color: teal;
}

.singlePostIcon:last-child {
  color: tomato;
}

.singlePostInfo {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-family: "Varela Round", sans-serif;
  color: #b39656;
}

.singlePostDesc {
  color: #666;
  font-size: 18px;
  line-height: 25px;
}

.singlePostDesc::first-letter {
  margin-left: 20px;
  font-size: 30px;
  font-weight: 600;
}

.singlePostDescInput {
  border: none;
  color: #666;
  font-size: 18px;
  line-height: 25px;
}

.singlePostDescInput:focus {
  outline: none;
}
.singlePostButton {
  width: 100px;
  border: none;
  background-color: teal;
  padding: 5px;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  align-self: flex-end;
  margin-top: 20px;
}

.backButton {
    justify-content: flex-end;
    display: flex;
    position: fixed ;
    margin: 10px;
    right:    0;
    bottom:   0;}


*/
.contact3 {
  font-family: "Montserrat", sans-serif;
  color: #8d97ad;
  font-weight: 300;
}

.contact3 h1,
.contact3 h2,
.contact3 h3,
.contact3 h4,
.contact3 h5,
.contact3 h6 {
  color: #3e4555;
}

.contact3 .font-weight-medium {
  font-weight: 500;
}

.contact3 .card-shadow {
  box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
}
.boxes{
  padding: 20px;
  align-items: center;
}

.contact3 .btn-danger-gradiant {
  background: #ff4d7e;
  background: linear-gradient(to right, #ff4d7e 0%, #ff6a5b 100%);
}

.contact3 .btn-danger-gradiant:hover {
  background: #ff6a5b;
  background: linear-gradient(to right, #ff6a5b 0%, #ff4d7e 100%);
}
.head-text {
  position: relative;
  color: white;
}
.center__text {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.shopImg {
  background-size: 100% 100%;
  width: 100%;
  object-fit: cover;
}

