.singlePost {
  flex: 9;
}

.singlePostWrapper {
  /* background-color: #cccccc; */
  padding: 30px;
  display: flex;
  flex-direction: column;
}

img{
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  max-height: 100vh;
  margin: auto;
}

.singlePostImg {
  width: 100%;
  height: 400px;
  border-radius: 5px;
  object-fit: contain;
}

.singlePostTitle {
  text-align: center;
  margin: 10px;
  font-family: "Lora", serif;
  font-size: 28px;
}

.singlePostTitleInput {
  margin: 10px;
  font-family: "Lora", serif;
  font-size: 28px;
  text-align: center;
  border: none;
  color: gray;
  border-bottom: 1px solid lightgray;
}

.singlePostTitleInput:focus {
  outline: none;
}

.singlePostEdit {
  float: right;
  font-size: 16px;
}

.singlePostIcon {
  margin-left: 10px;
  cursor: pointer;
}

.singlePostIcon:first-child {
  color: teal;
}

.singlePostIcon:last-child {
  color: tomato;
}

.singlePostInfo {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-family: "Varela Round", sans-serif;
  color: #b39656;
}

.singlePostDesc {
  color: #666;
  font-size: 18px;
  line-height: 25px;
}

.singlePostDesc::first-letter {
  margin-left: 20px;
  font-size: 30px;
  font-weight: 600;
}

.singlePostDescInput {
  border: none;
  color: #666;
  font-size: 18px;
  line-height: 25px;
}

.singlePostDescInput:focus {
  outline: none;
}
.singlePostButton {
  width: 100px;
  border: none;
  background-color: teal;
  padding: 5px;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  align-self: flex-end;
  margin-top: 20px;
}
